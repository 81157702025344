// mixins
@mixin flex-buttons {
  display: flex;
  justify-content: space-between;
  font-family: $rubik;
}

.drawings {
  &-tabs {
    border-radius: 1.25rem;
    border: 1px solid #e1e1e1;
    background: #f8f8f8;
    box-shadow: 0px 4px 4px 0px rgba(177, 177, 177, 0.25);
    width: max-content;

    &_tab {
      color: #000 !important;
      font-family: Rubik !important;
      font-size: 0.875rem !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }

    button.Mui-selected {
      //   color: #1976d2;
      border-radius: 1.25rem !important;
      background: #fff !important;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;
      color: #000 !important;
      text-align: center !important;
      font-family: $rubik !important;
      font-size: 0.875rem !important;
      font-style: normal !important;
      font-weight: 500 !important;
      align-self: center !important;
      min-height: fit-content !important;
      margin: 0 !important;
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      color: #000 !important;
      font-family: $rubik !important;
      font-size: 0.875rem !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
      margin-right: 7px;
    }
  }

  &-cardContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(280px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
  }
  &-card {
    border-radius: 1.25rem;
    border: 1px solid #e1e1e1;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(177, 177, 177, 0.25);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    height: 9.375rem;
    padding: 0px 20px;

    &_title {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &-icon {
        margin-right: 1rem;
        width: 3rem;
        height: 3rem;
      }
      &-heading {
        color: #010101;
        font-family: $rubik;
        font-size: 1rem !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal;
      }
    }

    &_seperator {
      height: 0.20231rem;
      border-radius: 1.25rem;
      background: #f8f8f8;
    }

    &_actions {
      @include flex-buttons;
      &-addBtn {
        border-radius: 0.625rem;
        background: #000;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
        height: 2.09081rem;
        color: #fff;
        width: 7rem;
      }
      &-moreBtn {
        border-radius: 0.625rem;
        border: 1px solid #000;
        background: #fff;
        height: 2.09081rem;
        width: 7rem;
      }
    }
  }

  &-cardCR {
    border-radius: 20px;
    border: 1px solid #e1e1e1;
    background: #fff;
    box-shadow: 0px 4px 4px 1px rgba(177, 177, 177, 0.25);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    height: auto;
    padding: 25px 20px;
    cursor: pointer;

    &_title {
      
      align-items: center;
      justify-content: flex-start;

      &-icon {
        //margin-right: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        box-shadow: 1px 1px 11px 6px #e5e5e5;
      }
      &-heading {
        color: #010101;
        font-family: $rubik;
        font-size: 1rem !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      &-subheading {
        color: #666;
        font-family: $rubik;
        font-size: 0.9rem !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }

      &-subheading2 {
        color: crimson;
        font-family: $rubik;
        font-size: 0.6rem !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-transform: uppercase;
      }
      &-subheading3 {
        color: grey;
        font-family: $rubik;
        font-size: 0.6rem !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: normal;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-transform: uppercase;
      }
      &-subheading4 {
        color: grey;
        font-family: $rubik;
        font-size: 0.6rem !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }

    &_outerIcon {
      padding:5px;
      //width:50px;
      //background-color: #e5e5e5;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &_seperator {
      height: 1px;
      background: #f8f8f8;
      margin-bottom: 15px
    }

    &_actions {
      @include flex-buttons;
      &-addBtn {
        border-radius: 0.625rem;
        background: #000;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
        height: 2.09081rem;
        color: #fff;
        width: 7rem;
      }
      &-moreBtn {
        border-radius: 0.625rem;
        border: 1px solid #000;
        background: #fff;
        height: 2.09081rem;
        width: 7rem;
      }
    }
  }

  &-addNew {
    &_btns {
      display: flex;
      justify-content: flex-end;
      font-family: $rubik;
      height: 100%;
      align-items: flex-end;
      &-cancelBtn {
        margin-right: 2rem;
      }
    }
  }

  &-modal {
    // .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 35rem;
    position: relative;
    // padding: 0rem 3rem;
    // display: flex;
    // align-items: stretch;
    // }

    &-revised {
      & .drawings-modal {
        width: 100%;
        height: 100%;

        &Container {
          border-left: 0;

          & .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
            color: #8d8d8d !important;
            font-family: $rubik !important;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500 !important;
            line-height: normal;
          }

          &_span {
            color: rgba(138, 138, 138, 0.5);
            font-family: $rubik;
            font-size: 0.8125rem;
            font-style: italic;
            font-weight: 500;
            line-height: normal;
            width: 103%;
          }
        }
      }

      &_addBtn {
        align-self: self-end;
        display: flex;
        justify-content: center;
      }
    }

    &-revision {
      & .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        max-width: 80rem !important;
        width: 100%;
        height: max-content;
      }

      & .drawings-modal {
        width: 100%;
        height: 100%;

        &Container {
          border-left: 0;

          & .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
            color: #8d8d8d !important;
            font-family: $rubik !important;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500 !important;
            line-height: normal;
          }

          &_span {
            color: rgba(138, 138, 138, 0.5);
            font-family: $rubik;
            font-size: 0.8125rem;
            font-style: italic;
            font-weight: 500;
            line-height: normal;
            width: 103%;
          }
        }
      }

      &_addBtn {
        align-self: self-end;
        display: flex;
        justify-content: center;
      }

      &View {
        .MuiDialog-paperScrollPaper {
          max-width: 75% !important;
          height: max-content;
        }
      }
    }

    &Container {
      // width: 80%;
      // padding: 0rem 3rem;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      // border-left: 0.31rem solid #ffc700;
      justify-content: space-evenly;
      // margin-left: 2rem;
      // margin-top: 2rem;
      padding-left: 1rem;
      // height: 8rem;
      justify-content: space-between;
      margin: 2rem;
    }

    &_header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-title {
        &1 {
          color: #000;
          font-family: $rubik;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &2 {
          color: #8d8d8d;
          font-family: $rubik;
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }

    &_content {
      display: flex;
      justify-content: space-between;

      &-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 4rem;
        justify-content: space-evenly;
        &Title {
          color: #8d8d8d;
          text-align: center;
          font-family: $rubik;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &SubTitle {
          color: #000;
          text-align: center;
          font-family: $rubik;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    &_closeIcon {
      position: absolute;
      top: 4px;
      right: 3px;
      cursor: pointer;
    }
  }

  &-uploadBtn {
    border-bottom: 1px solid gray !important;
    width: 100% !important;
    border-radius: 0px !important;
    display: flex !important;
    justify-content: space-between !important;
    font-family: $rubik !important;
    color: #454444 !important;
    text-transform: none !important;
    padding: 10px 10px 10px 0px !important;
    color: #8d8d8d !important;
    font-size: 0.875rem;
    font-weight: 500;

    &:hover {
      background: transparent !important;
    }
  }

  &-addDocBtn {
    width: max-content;
    position: absolute;
    right: 2rem;
    font-family: $rubik;
  }

  &-tooltip {
    height: max-content;
    width: 5rem;
    color: #6d6d6d;
    border-radius: 5px;
    border: 1px solid #c3c3c3;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: "Rubik";
    margin: 0;
    padding: 5px;
    text-align: start;
    background-color: #ffffff;
    position: absolute;
    bottom: -224%;
    left: 12%;
    display: flex;
    flex-direction: column;
  }

  &-revisions {
    &_modal {
      &-tableContainer {
        overflow-y: scroll;
        height: 18rem;
      }
    }
  }

  &-table {
    &_revision {
      color: #4d7eff;
      text-align: center;
      font-family: $rubik;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      border: none;
      background: none;
    }
  }
}

.MuiTabs-indicator {
  background-color: transparent !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 1.25rem !important;
}
