

@import 'variables'; // Adjust path as necessary

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

body {
  font-family: $rubik;
}
.sidebar {
  background-color: $primary-background-color;

  &-logo {
    // margin:12px 0px 0px 42px;
    // flex: none;
    width: 100%;
    // height: 42px;
    // border: 1px #666 solid;
    padding: 0px 46px;
    font-family: $rubik;

    &:hover {
      background: none !important;
      // cursor: pointer;
    }
  }
  &-icon {
    width: 100%;
    padding: 0 15px;
  }

  &-logo-holder {
    display: block;
    padding: 0 !important;
    .nav-link.active {
      border-left: none !important;
    }
  }

  .fixed-sidebar &-logo-holder {
    display: none;
  }

  &-icon-holder {
    display: none !important;
  }

  .fixed-sidebar &-icon-holder {
    display: block !important;
    padding: 0rem;
    .sidebar-icon {
      height: 2rem !important;
      width: 4rem !important;
      // margin-left: -1rem !important;
    }
    .nav-link.active {
      border-left: none !important;
    }
  }

  &-nav_icon {
    margin-right: 0.8rem;

    &Path {
      fill: blueviolet;

      &:hover {
        fill: #fff;
      }
    }

    & g path :hover {
      fill: red;
    }
  }
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 4px solid #555555;
}
.nav-item {
  margin: 1rem 0rem;
}
.info-btn {
  position: absolute;
  top: 15px;
  right: 56px;
  color: #d3d1d1;
  cursor: pointer;
}
.info-content.visible {
  display: block;
}

.info-content.hidden {
  display: none;
}
.info-content {
  position: absolute;
  top: -64px;
  font-size: 12px;
  background: black;
  text-align: center;
  color: white;
  padding: 10px;
  border-radius: 10px;
}
.info-content::before {
  content: "";
  background: black;
  bottom: -9px;
  right: 18%;
  height: 9px;
  position: absolute;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  width: 15px;
}

.w-500px {
  width: 500px;
}
.border-bottom-1 {
  border-bottom: 1px solid #d4d4d4 !important;
}
.MuiAutocomplete-clearIndicator {
  display: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.autocomplete-filter {
  .MuiTextField-root {
    width: 122px !important;
  }
  input {
    font-family: $rubik !important;
    width: 122px !important;
  }
  .MuiInputBase-fullWidth {
    padding-right: 0px !important;
  }
  .MuiAutocomplete-endAdornment {
    display: none !important;
  }
}
.MuiOutlinedInput-notchedOutline {
  border-color: white !important;
  border: none !important;
  // border-bottom: 1px solid black !important;
  border-radius: 0px !important;
}
.revision-image-view {
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 1px 0px #000;
  margin: 0 10px;
  object-fit: cover;
  border-radius: 5px;
}

// .nav-item :hover {
//   background: orange;
// }
.sidebar-nav .nav-link.active {
  border-left: 3px #fff solid;
}

.sidebar-nav .nav-link {
  font-family: $rubik;
}

.sidebar-nav .nav-link.active svg path {
  fill: #efefef !important;
}

.sidebar-nav .nav-link:hover svg path {
  // &:hover {
  fill: #efefef !important;
  // }
}

// .sidebar-nav .nav-link.active,
// .ci-primary {
//   fill: yellow !important;
// }

.sidebar-nav::-webkit-scrollbar {
  display: none;
}

.fixed-sidebar {
  --cui-sidebar-width: 4rem;

  &:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 4rem;
  }
}

.fixed-sidebarNav::-webkit-scrollbar {
  display: none;
}

//Header
.header {
  &-menu {
    width: 2.188rem;
    height: 2.188rem;
    color: #000;
  }

  &-brand {
    color: #000;
    font-family: $rubik;
    font-weight: 700;
  }

  &-nav {
    display: flex;
    align-items: center;

    &:hover {
      background: none;
    }
  }

  &-dashboard {
    color: #000 !important;
    text-align: center;
    font-family: $rubik;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.04px;
  }

  &-username {
    color: #000;
    text-align: center;
    font-family: $rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.8px;

    &Div {
      border-left: 1px #f2f2f2 solid;
    }
  }

  &-user_icon {
    & Path {
      fill: black;
    }
  }
}

.custom-header {
  &_SearchIcon {
    background: "none";
  }

  &_font {
    font-size: $header-font-title;

    &subtitle {
      font-size: $header-font-subtitle;
    }
  }

  &_lastPart {
    width: 11rem;
  }

  &_user {
    display: flex;
    align-items: center;
  }
}

.dashboard {
  &-card_height {
    height: 21rem !important;
  }
}

.page {
  &-header {
    color: #010101;
    font-family: $rubik;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.64px;
    margin-bottom: 2rem;
  }
}

.custom-card {
  background-color: $primary-background-color !important;
  border-radius: $card-border-radius;
  cursor: pointer;

  &_light {
    border: 1px solid #e1e1e1;
    background-color: #fff !important;
    border-radius: $card-border-radius;
    cursor: pointer;
  }

  &_section {
    display: flex;

    &First {
      &-title {
        font-size: 1.875rem !important;
        color: #fff;
        font-family: $rubik;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
        letter-spacing: -1.2px;
        margin-bottom: 4px !important;

        &_light {
          color: #303030;
          font-size: 1.875rem !important;
          font-family: $rubik;
          font-style: normal;
          font-weight: 500 !important;
          line-height: normal;
          letter-spacing: -1.2px;
          margin-bottom: 4px !important;
        }
      }

      &-subtitle {
        color: #f2f2f2;
        // text-align: center;
        font-family: $rubik;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.64px;

        &_light {
          color: #303030;
          font-family: $rubik;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.64px;
        }
      }
    }

    &Percentage {
      display: flex;
      justify-content: space-between;

      color: #c1c1c1;
      font-family: $rubik;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.56px;

      &_light {
        display: flex;
        justify-content: space-between;

        color: #666;
        font-family: $rubik;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.56px;
      }
    }

    &Progress {
      --cui-progress-height: 0.75rem;
      --cui-progress-bg: #595959;

      &_light {
        --cui-progress-bg: #f2f2f2;
        --cui-progress-height: 0.75rem;
      }

      &-In {
        --cui-progress-bar-bg: #f2f2f2;
        width: 0%;

        &_light {
          --cui-progress-bar-bg: #b1e0cf;
          width: 0%;
        }
      }
    }
  }

  &_Icon {
    &-background {
      background: #353131 !important;
      width: 2.8rem;
      border-radius: 0.3rem;

      & .ci-primary {
        fill: #fff;
      }

      &_light {
        width: 2.8rem;
        border-radius: 5px;
        background: rgba(177, 224, 207, 0.85);

        & .ci-primary {
          fill: black;
        }
      }
    }

    &-button {
      width: 1rem !important;
      height: 2.25rem !important;
      display: flex;
      margin: auto;
    }
  }
}

.SignInPage-Container {
  /* The image used */
  background-image: url("../assets/images/bg-image.jpg");

  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
}

.navbar-brand {
  font-size: 3rem;
  font-weight: 600;
  color: #000000;
  font-family: $rubik;
  letter-spacing: -1px;
}

.borderless-btn {
  // color: #303030;
  // font-weight: 700;

  color: #303030;
  text-align: right;
  font-family: $rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.64px;
  width: 9.688rem;
  border: none
}

.btn-dark {
  background-color: #000000;
  font-size: 1rem;
  font-weight: 700;
}

.form {
  // height: 30rem;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.29);

  &-heading {
    margin-bottom: 0.6rem;

    color: #303030;
    font-family: $rubik;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
  }

  &-container {
    height: 70vh;
  }

  &-elements {
    &-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &-terms {
    width: 20rem;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0.7rem;
    align-items: center;

    &_checkbox {
      height: 1rem;
      width: 1rem;
      accent-color: black;
    }

    &_label {
      color: #000;
      text-align: center;
      font-family: $rubik;
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &-inputGroup {
    position: relative;
    color: #666666;
    margin-bottom: 0.7rem;

    &_Icon {
      position: absolute;
      right: 7%;
      top: 35%;
      color: lightgray;
    }

    &_Button {
      background-color: black;
      width: 20rem;
      height: 3rem;
      font-weight: 700;

      color: #fff;
      text-align: center;
      font-family: $rubik;
      font-size: 1.1rem;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.8px;
    }

    &_googleBtn {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 3rem;
      width: 12.5rem;
      color: grey;
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.1);
      border: none;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-weight: 500;
      font-family: "Rubik";
      font-size: 0.9rem;
    }

    &_Para {
      display: flex;
      width: 80%;
      justify-content: center;

      color: #000;
      text-align: center;
      font-family: $rubik;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &-link {
        text-decoration: none;
        color: black;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          color: black;
        }
      }
    }
  }
}

.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

.Slider {
  height: 100%;

  &-Container {
    height: 25rem;
    width: 23rem;
  }

  &Image {
    height: 12rem;
    width: 12rem;
    margin-bottom: 1rem;
    background: #f2f2f2;
    border-radius: 50%;
    padding: 5px;
    object-fit: cover;
  }

  &Heading {
    // font-size: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 700;

    color: #000;
    font-family: $rubik;
    font-size: 1.5rem;
    line-height: normal;
  }

  &Page {
    color: #303030;
    text-align: center;
    font-family: $rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.carousel-dots li {
  margin: 0 !important;
}

.carousel-prev.block,
.carousel-next.block {
  opacity: 0;
}

.SignUp {
  &Image {
    height: 10rem;
    width: 10rem;
    background: #f2f2f2;
    padding: 15px;
    border-radius: 50%;
  }

  &Heading {
    font-weight: 700;
    font-size: 28px;
    text-align: center;
  }

  &Para {
    color: #303030;
    text-align: center;
    font-family: $rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &_2 {
      width: 90%;
    }
  }

  &-getInTouchBtn {
    border: 1px solid #303030;
    border-radius: 15px;
    width: auto;
    height: 42px;
    background: transparent;
    color: black;
  }
}

.thankingPage {
  display: flex;
  // height: 30rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem 3.5rem;
  justify-content: space-evenly;

  &-heading {
    color: #303030;
    font-family: $rubik;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.8px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &-para {
    color: #303030;
    text-align: center;
    font-family: $rubik;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 85%;
  }

  &-button {
    color: #fff;
    text-align: center;
    font-family: $rubik;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.8px;
  }
}

.svg {
  display: flex;
  width: 20%;
  justify-content: space-evenly;
  margin-top: 1rem;

  &Icon {
    height: 1.25rem;
    width: 1.25rem;
    color: #666666;
  }
}

.footer-copyrights {
  color: #000;
  text-align: center;
  font-family: $rubik;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1.5rem;
}

.pieChart {
  &-header {
    color: #303030;
    text-align: center;
    font-family: $rubik;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.8px;
  }

  &-subHeader {
    font-size: 0.625rem;
    text-align: center;
    font-family: $rubik;
    color: #666;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &_header {
      font-size: 0.7rem;
      text-align: center;
      font-family: $rubik;
    }

    &_heading {
      width: 7.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      font-family: $rubik;
      color: #303030;
    }

    &_vr {
      position: absolute;
      height: 100%;
      right: 47%;
      background-color: lightgray;
    }
  }

  &-container {
    height: 20%;
    margin-bottom: 0.8rem;
  }

  &-div {
    position: relative;
  }

  &-1 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  &-dot {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: orange;
  }

  &-para {
    font-size: 0.9rem;
    font-weight: 600;
    font-family: $rubik;
    color: #666;
  }

  &-externalLink {
    width: 10px;
  }

  &-hr {
    position: absolute;
    top: -5px;
    left: 14px;
    width: 100%;
    color: red;
  }
}

.projectList {
  &-header {
    color: #000;
    // text-align: center;
    font-family: $rubik;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.8px;

    &_content {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &-search {
    border-radius: 25px;
    border: 1px solid #e1e1e1;
    width: 12.5rem;
    height: 2rem;
    text-indent: 10px;
    font-size: 0.75rem;

    &-div {
      position: relative;
      margin-right: 2rem;
    }

    &Icon {
      position: absolute;
      top: 25%;
      right: 6%;
      height: 1rem;
      width: 1rem;
    }

    &::placeholder {
      text-align: left;
    }
  }

  &-btnGroup {
    display: flex;
  }

  &btn {
    background: #f2f2f2;
  }

  &-btn {
    border: none;
    color: #444;
    text-align: center;
    font-family: $rubik;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.44px;
    width: 4.75rem;
  }

  &-active_btn {
    background-color: black;
    color: white;
  }

  &-table {
    &_header {
      color: #666666;
      font-size: 14px;
      font-family: $rubik;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.56px;

      &-rowcell {
        font-weight: 400 !important;
      }
    }

    &_row {
      height: 5.25rem;

      &:last-child {
        border: 0px transparent !important;
      }
    }

    &_ellipsisIcon {
      color: #808080;
      cursor: pointer;
    }

    &_status {
      width: inherit;
      // background: #b1e0cf;
      border-radius: 0.938rem;
      font-size: 0.938rem;
      font-weight: 400;
      padding: 3px;
      //   width: 6.688rem;
    }

    &_fullview {
      &_location {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 10rem;
        justify-content: space-between;

        &Heading {
          display: flex;
          align-items: flex-end;
          position: relative;
        }

        &Header {
          margin-bottom: 0;
          color: #010101;
          font-size: 1.125rem;
          font-family: $rubik;
          font-weight: 600;
        }

        &Icon {
          margin-right: 0.5rem;
        }

        &Info {
          position: absolute;
          right: -1.5rem;
          top: 2px;
          cursor: pointer;
        }

        &Content {
          height: 70%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          color: #6d6d6d;
          font-size: 1rem;
          font-family: $rubik;

          &-client {
            display: flex;
            width: max-content;
            justify-content: space-between;

            & span {
              margin-right: 1rem;
            }
          }

          &-resident {
            color: #6d6d6d;
            font-size: 1rem;
            font-family: $rubik;

            &-div {
              display: flex;
            }
          }
        }
      }

      &_vr {
        height: 11.875rem;
        width: 0.56rem;
        color: #72cb62;
        opacity: 1;
      }

      &_startson {
        width: max-content;
        font-family: $rubik;

        &-div {
          position: relative;
        }

        &-span {
          font-weight: 500;
          color: #6d6d6d;
        }
      }

      &_moredetailsbtn {
        width: 10rem;
        height: 3.125rem;
        border: 1px;
        box-shadow: 0px 4px 4px 0px #00000040;
        background: transparent;
        border-radius: 3px;
        border-style: solid;
        border-width: thin;
        font-family: $rubik;
        font-weight: 600;

        position: absolute;
        left: 0%;
        top: 190%;

        &-Icon {
          margin-left: 1rem;
        }
      }
    }
  }

  &-tooltip {
    height: 5rem !important;
    position: absolute !important;
    bottom: -144% !important;
    display: flex !important;
    flex-direction: column !important;
    width: fit-content !important;
    right: -331% !important;

    &_Btn {
      width: max-content;
    }
  }
}

.view {
  &-header {
    display: flex;
    justify-content: space-between;
    padding-left: 1.2rem;
    padding-right: 2rem;
  }
}

.toggleBtn {
  width: 4.125rem;
  height: 1.25rem;
  font-size: 0.688rem;
  font-family: $rubik;
  border: none;
}

.tooltipText {
  height: 5rem;
  width: 15rem;
  background-color: $tooltip-bg;
  color: #6d6d6d;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: $rubik;

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 96%; /* To the left of the tooltip */
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }

  & li {
    // font-size: 30px;
    // width: 20px;

    & span {
      font-size: 13px;
    }
  }
}

// project dashboard
.project-dashboard {
  &-card-body {
    padding: 0.6rem 1rem;

    .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
      color: #8d8d8d !important;
      font-family: $rubik !important;
      font-size: 0.875rem !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;
    }
  }
  & .project-header {
    position: relative;
    margin-bottom: 50px;
    background-size: cover;
    background-position: center;
    border-radius: 0;
    height: 300px;
    z-index: 1;
    border-bottom: 1px solid #e1e1e1;
    &:before {
      content: "";
      width: 100%;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), transparent);
      height: 100%;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    & .project-image {
      width: 15rem;
      height: 10rem;
      object-fit: cover;
      position: absolute;
      bottom: -50px;
      left: 50px;
    }
    & .project-title {
      font-size: 1.75rem;
      letter-spacing: -0.64px;
      font-weight: 600;
    }
    & .project-title,
    & .project-location {
      margin-left: 1.5rem;
      letter-spacing: -0.64px;
    }
  }

  &_picEdit {
    position: relative;
    bottom: 98px;
    left: 208px;
  }

  &_coverPicEdit {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 20px;
  }

  &_picUploadBtn {
    cursor: pointer;
    background: white;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #5d5252b5;
    box-shadow: 2px 1px;

    &2 {
      cursor: pointer;
      background: white;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #5d5252b5;
      box-shadow: 2px 1px;
    }
  }

  &_picUploadInput {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}

// Add Project
.addProject {
  &-header {
    border: 0;
    padding-left: 1rem;
  }

  &-saveBtn {
    padding-left: 2rem !important;
  }
}

//Accordion
.accordion {
  &-header {
    color: #161616;
    font-family: $rubik;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-height: auto;
    padding-bottom: 0;
    // padding-top: 1.6rem;
  }

  &-item {
    border: none;
  }

  &-textField {
    width: 20rem;
    // color: #8D8D8D;
    color: #454444;
  }

  &-button {
    font-family: $rubik;
    font-weight: 500;
    font-size: 1.125rem;
    color: #161616;

    &:focus {
      border-color: none;
      outline: 0;
      box-shadow: none;
    }

    &:not(.collapsed) {
      color: black;
      background-color: transparent;
      box-shadow: none;

      &::after {
        background-image: var(--cui-accordion-btn-icon);
      }
    }
  }

  &-saveBtn {
    height: 2.15rem !important;
    margin: 2.5rem .5rem 1rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #fff !important;
    text-align: center !important;
    font-family: $rubik !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    text-transform: capitalize !important;
    letter-spacing: -0.72px !important;
    width: max-content !important;
    border-radius: 5px !important;
    padding-left: 2rem !important;
    background: black !important;
    padding-right: 2rem !important;
    box-shadow: 1px 1px 10px 1px #66666699;
  }
}


/* add project page checkbox */

.checkbox-label {
  font-size: 0.85rem; 
  color: #555; 
  font-family: $rubik;
  
}

.checkbox {
  color: #555; 

  &.Mui-checked {
    color: #555; 
  }
}



//Milestone
.milestone {
  // display: flex;
  // align-items: center;
  // margin: 2rem 0rem;
  width: 100%;
  position: relative;

  &-card_height {
    height: 38.563rem;
  }

  &-title {
    margin: 1.25rem;
    letter-spacing: -0.64px;
    font-size: 1rem;
  }

  &-body {
    padding-top: 0px;
    overflow-x: auto;
  }

  &-right {
    // margin-top: 6rem;
    // width: 100%;
    // margin: 0;
    // align-self: end;

    width: 100%;
    display: grid;
    align-self: end;
    height: 8rem;
    align-items: end;
    position: relative;
  }

  &-div {
    display: grid;
    grid-template-columns: auto auto;
    position: relative;
  }

  &-container {
    display: flex;
    align-items: center;
    height: auto;
    margin-bottom: 15px;
  }

  &-details {
    text-align: right;
    display: flex;
    flex-direction: column;
    height: 45%;

    &_right {
      text-align: left;
    }
  }

  &-date {
    font-size: 0.8rem;
    font-family: $rubik;
    font-weight: 500;
  }

  &-name {
    font-size: 0.688rem;
    font-family: $rubik;
    font-weight: 600;
    color: #000;
  }

  &-hr {
    height: 0.18rem;
    background: #37ea73;
    margin-top: 3px;
    margin-bottom: 3px;
    opacity: 1;
    border: none;
    width: auto;
  }

  &-content {
    text-align: right;
    color: #444;
    font-size: 0.68rem;
    font-family: $rubik;
    margin: 0;
    height: 45%;
    width: 140px;
    white-space: normal;
    text-overflow: ellipsis;

    &Div {
      height: auto;
      display: flex;
      margin-right: 2px;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-end;

      &_right {
        align-items: flex-start;
        margin-left: 2px;
      }
    }

    &_right {
      text-align: left;
    }
  }

  &-images {
    position: relative;
    height: 40px;
    width: 40px;
    border: 2px solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-img {
    // position: absolute;
    // left: 25%;
    // bottom: 22%;
    // position: absolute;
    // left: 22%;
    height: 75%;
    // bottom: 22%;
    width: 75%;
    object-fit: cover;
  }

  &-sideHr {
    // width: 4.2rem;
    // height: 3px;
    // border-top: 1px dashed;
    // margin: 0;
    // opacity: 1;
    // transform: rotate(50deg);

    //positioning the horizontal line between the mlestones
    // position: absolute;
    // left: 42%;
    // top: 100%;

    // width: 3.1rem;
    // border-top: 2px dashed;
    // margin: 0;
    // opacity: 1;
    // transform: rotate(35deg);
    // position: absolute;
    // top: 48%;
    // right: 88%;

    width: 7.8rem !important;
    border-top: 2px dashed;
    margin: 0;
    opacity: 1;
    transform: rotate(24deg);
    position: absolute;
    top: 50% !important;
    right: 96% !important;

    &2 {
      // width: 4rem;
      // border-top: 2px dashed;
      // transform: rotate(-50deg);
      // position: absolute;
      // left: 85%;
      // top: -15%;
      // opacity: 1;
      margin: 0;
      width: 8rem !important;
      border-top: 2px dashed;
      transform: rotate(155deg);
      position: absolute;
      left: 97% !important;
      top: -25% !important;
      opacity: 1;
    }
  }

  &-summary {
    &_cardBody {
      padding-right: 4.375rem;
    }

    &_table-row {
      height: 3.87rem !important;
    }

    &_actionIcon {
      color: #808080;
      cursor: pointer;
      height: 1rem;
      width: 2rem;
    }

    &-tooltipBtn {
      font-family: $rubik;
      border: none;
      background-color: #fff;
      color: #303030;
      font-size: 16px;
      letter-spacing: -0.64px;
      font-weight: 600;
    }

    &_startDate {
      color: #303030;
      font-size: 13px !important;
      font-family: $rubik;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.52px;
    }

    &_tableStatusData {
      width: 7rem;
    }

    &_status {
      font-size: 0.813rem;
      // width: max-content;
      padding: 8px 16px;
    }
  }
}
.milestonenew-left::before {
  content: "";
  position: absolute;
  right: -40%;
  width: 39%;
  top: 50%;
  border-top: 2px dashed;
  margin: 0px;
  opacity: 1;
  transform: rotate(23deg);
}
.milestonenew-right::before {
  content: "";
  position: absolute;
  left: -40%;
  width: 41%;
  bottom: -3%;
  border-top: 2px dashed;
  margin: 0px;
  opacity: 1;
  transform: rotate(151deg);
}

// Milestone-summary
.summary-tooltip {
  height: 3.313rem;
  width: 5.313rem;
  color: #6d6d6d;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: $rubik;
  margin: 0;
  padding: 5px;
  text-align: start;
  background-color: #ffffff;
  position: absolute;
  bottom: -80%;

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #aaaaaa transparent transparent;
  }

  & span {
    font-size: 10px;
  }

  &-teamMembers {
    width: 10rem;
    left: -90%;
  }
}

//Override In-built classes
.css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid gray !important;
  font-family: $rubik !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: gray !important;
  font-family: $rubik !important;
  font-weight: 500;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: #8d8d8d;
  font-size: 0.875rem;
  font-family: $rubik !important;
  font-weight: 500 !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  font-family: $rubik !important;
  color: #8d8d8d !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  // font-weight: 500 !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-size: 12px !important;
  font-family: $rubik !important;
  font-weight: 500 !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  font-family: $rubik !important;
  color: #8d8d8d !important;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: $rubik !important;
}

.css-66dh3a-MuiInputBase-input-MuiInput-input {
  font-family: $rubik !important;
}

.MuiInputBase-input.MuiInput-input {
  padding-bottom: 10px !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  padding-top: 5px !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  font-family: $rubik !important;
}

.css-q0jhri-MuiInputBase-root-MuiInput-root {
  font-family: $rubik !important;
}

.MuiFormLabel-root {
  font-family: $rubik !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root {
  font-family: $rubik !important;
}

.css-1rcvvn7-MuiInputBase-root-MuiInput-root {
  font-family: $rubik !important;
  margin-top: 0.9rem;
  // font-size: 12px !important;
  font-weight: 500 !important;
}

.tooltip-inner {
  background-color: transparent;
}

//payment - info

// table
.table {
  &-datacell {
    color: #303030;
    text-align: center;
    font-family: $rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.64px;

    &_title {
      text-align: left;
      // color: #cd9cf2 !important;
      font-weight: 600;
    }
    &_category {
      text-align: left;
      // color: #cd9cf2 !important;
    }
    &_downloadFile {
      text-decoration: none;
      color: #303030 !important;
    }
  }
}

//payment - timeline
.payment-timeline {
  &_header {
    background: transparent;
    border: 0;
    color: #161616;
    font-family: $rubik;
    font-size: 1rem;
    letter-spacing: -0.64px;
    font-style: normal;
    font-weight: 500;
    padding: 1rem;
    margin: 0;
  }

  &_lineChart {
    padding: 1.3rem 0rem;
  }

  &_chartSection {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $rubik !important;
  }

  &_chartDiv {
    display: flex;
    // width: 25%;
    justify-content: space-between;
    font-size: 12px;
    font-family: $rubik;
  }
}

//payment - summary
.payment-summary {
  &_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  &_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    background: #fff;
    height: 4rem;
    width: 10rem;
    justify-content: center;

    &-first {
      border: none;
    }

    &Title {
      color: #666;
      font-family: $rubik;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;

      // padding: 0.625rem 1.25rem;
    }

    &Price {
      color: #303030;
      font-family: $rubik;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 3px 0px 0px;
    }
  }
}

//react calendar
// .react-calendar__tile:enabled:hover,
// .react-calendar__tile:enabled:focus {
//   background-color: blue;
// }
.react-calendar {
  border: none;
  font-family: $rubik;

  &__month-view__weekdays__weekday {
    padding: 0.5em 0em;
  }

  &__navigation {
    margin: 0;
  }

  &__tile {
    border-radius: 60% !important;
    font-size: 10px;
    padding: 7px !important;

    &:hover {
      background: none !important;
    }

    & abbr {
      border-radius: 70% !important;
      padding: 0.3rem !important;
      display: inline-grid;
      height: 25px;
      width: 26px;
      &:hover {
        background: #e6e6e6 !important;
        color: #000 !important;
      }
    }
    // margin: 2px 5px !important;

    &--active {
      background: none;
      & abbr {
        background: #233e8f;
        color: white !important;
      }
      &:enabled:hover,
      &:enabled:focus {
        background: none !important;
      }
    }
    &--now {
      background: none;
      & abbr {
        border: 1px gray solid !important;
        border-style: solid;
        // background: black;
        color: black;
      }

      &:focus {
        background: none;
        & abbr {
          background: blue;
        }
      }

      &:hover {
        background: none !important;
        & abbr {
          background: #fcfcfc !important;
          color: black;
        }
      }
    }
  }

  &_upcomingDates > {
    & abbr {
      background-color: palegreen !important;
      color: black;
    }
  }

  &_missedDates {
    & abbr {
      background-color: palevioletred !important;
      color: black;
    }
  }

  &_content {
    &-container {
      border-left: 1px #e1e1e1 solid;
      padding-left: 0px;
    }

    &-toggleBtnContainer {
      height: 10%;
      display: flex;
      justify-content: flex-end;
      align-items: start;
    }

    &-card {
      &Div {
        height: 80%;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
      height: 20%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-left: 0.4rem;
      // border-bottom: 1px #e1e1e1 solid;
    }

    &-homeIcon {
      height: 0.75rem;
      width: 0.75rem;
      margin-top: 2px;
    }

    &-header {
      &Div {
        display: flex;
      }

      color: #303030;
      text-align: center;
      font-family: $rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.48px;
      margin: 0;
      margin-left: 4px;
    }

    &_para {
      margin: 0;

      &Div {
        display: flex;
        justify-content: space-evenly;
      }
    }

    &_para1 {
      color: rgba(48, 48, 48, 0.6);
      text-align: center;
      font-family: $rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    &_para2 {
      color: #0f5139;
      text-align: center;
      font-family: $rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
    }
  }
}

abbr[title] {
  text-decoration: none;
  text-transform: none;
  color: #aaaaaa;
  font-weight: 400;
}

// react-calendar -- arrow button
.react-calendar__navigation__arrow {
  transform: scale(2);
  font-weight: 500;
  background: none !important;

  &:hover {
    background: none !important;
  }
}

.react-calendar__navigation__label {
  background: none !important;

  &:hover {
    background: none !important;
  }
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

// Login pages
.login {
  &-header {
    &_right {
      width: 30% !important;

      &-requestBtn {
        color: #fff;
        text-align: center;
        font-family: $rubik;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        // line-height: normal;
        width: 12.5rem;
        height: 3.438rem;
      }

      &-watchBtn {
        color: #303030;
        background-color: transparent;
      }
    }
  }

  &-input {
    width: 20rem;
    height: 3rem;
    border: 1px solid #666;
    border-radius: 1.3rem;
    text-align: center;

    &::placeholder {
      color: #827e7e;
      font-family: $rubik;
      font-size: 0.86rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &-haveTrouble {
    color: #000;
    text-align: center;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    padding-top: 5px;
    line-height: normal;
  }

  &-or {
    color: #000;
    text-align: center;
    font-family: $rubik;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.signup {
  &-input {
    text-align: left;
    text-indent: 1rem;
    height: 2.8rem;
    &::placeholder {
      text-align: left;
      text-indent: 1rem;
    }
  }
}

// Minutes of Meeting (MOM)
.mom {
  &-action {
    &_tooltip {
      width: 10rem;
      height: 6.25rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      bottom: -190%;

      &Btn {
        margin-left: 1rem;
      }
    }
  }
}


/*styles for the coupon code dropdown */


.coupon-dropdown .dropdown-item span {
  font-size: 12px; /* Adjusted font size for coupon code and discount text */
}

.coupon-dropdown .dropdown-item span:last-child {
  font-size: 12px; /* Reduced font size for the discount limit text */
}

/* Apply button styles */
.apply-btn {
  background-color: #000;
  color: white;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 10px;
  line-height: 12px;
  border: none;
}

// Terms Of Scope

.termsofscope span {
  color: #666666;
  font-family: $rubik !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-bottom: 12px;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

// Photos page

.file {
  &-uploadBtn {
    font-family: $rubik !important;
    background-color: transparent !important;
    text-transform: none !important;
    margin-right: 1rem !important;

    color: #8d8d8d !important;
    font-size: 0.875rem !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;

    width: 9.063rem;
    justify-content: space-between !important;
  }

  &-uploadIcon {
    color: #4ecb71;
  }
}

.photos {
  &-upload {
    &_label {
      color: #8d8d8d;
      font-family: $rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &-container {
    padding: 1rem;
  }

  &-photo {
    object-fit: cover;
    height: 18.5rem;
    border-radius: 3px;
    border: 1px solid #e1e1e1;
    margin: 1px;
    // width: 20%;
  }

  &-tooltip {
    width: 490px;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    background: #ffffff;
    height: 242px;
    opacity: 10;
    position: relative;
    z-index: 2 !important;
    max-width: none;
    opacity: 1 !important;

    position: absolute !important;
    inset: auto auto 0px 0px !important;
    // transform: translate3d(100.667px, -42.6667px, 0px) !important;

    &_header {
      text-align: left;
      padding-left: 8px;
    }

    & .tooltip-inner {
      max-width: none;
      height: 100%;
    }

    &_container {
      color: black;
      height: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
    }

    &_inner {
      opacity: 1 !important;
      z-index: 3 !important;
    }
  }

  &-table {
    &::after {
      content: " ";
      position: absolute;
      top: 100%;
      left: 20%;
      margin-left: -5px;
      border-width: 15px;
      border-style: solid;
      border-color: #aaa transparent transparent transparent;
    }
    &_body {
      border-color: transparent !important;
    }
  }

  &-datepicker {
    position: relative;
    // margin-bottom: 5rem;

    .react-datepicker-wrapper {
      position: absolute;
      right: 2rem;
      margin-bottom: 2rem !important;
    }
  }
}

// custom file input
// .custom-file-input::-webkit-file-upload-button {
//   visibility: hidden;
// }
// .custom-file-input::before {
//   content: "Choose a File";
//   display: inline-block;
//   background: linear-gradient(top, #f9f9f9, #e3e3e3);
//   border: 1px solid #999;
//   border-radius: 3px;
//   padding: 5px 8px;
//   outline: none;
//   white-space: nowrap;
//   -webkit-user-select: none;
//   cursor: pointer;
//   text-shadow: 1px 1px #fff;
//   font-weight: 700;
//   font-size: 10pt;
// }
// .custom-file-input:hover::before {
//   border-color: black;
// }
// .custom-file-input:active::before {
//   background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
// }

// site-visit
.sitevisit {
  &-tab {
    &-button {
      color: #000 !important;
      text-align: center !important;
      font-family: $rubik !important;
      font-size: 0.875rem !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: normal !important;
      letter-spacing: -0.56px !important;
      border-bottom: 1px #d9d9d9 solid !important;
      margin: 0px 0.5rem !important;

      &:focus {
        background: #d9d9d9 !important;
        font-weight: 700 !important;
      }

      &_active {
        background: #d9d9d9 !important;
        font-weight: 700 !important;
      }
    }
  }

  &-addNotes {
    &_div {
      border: 1px solid #d9d9d9;
    }

    padding: 1rem;
    display: flex;
    align-items: flex-end;
    position: relative;

    &_textfield {
      margin-left: 1rem !important;
    }

    &Btn {
      height: 2.188rem;
      position: absolute;
      top: -25%;
      right: 0%;
    }
  }

  &-notepara {
    padding: 1rem;
    margin: 1rem 0rem;

    color: #000;
    font-family: $rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.52px;
  }

  &-Container {
    position: relative;
  }

  &-Icons {
    position: absolute;
    top: 20px;
    right: 20px;
    &_icon {
      cursor: pointer;
      font-size: 1.2rem;
      margin-right: 1rem;
    }
  }
}

.drawings span {
  background-color: transparent !important;
}

// Team Members
select.form-select.form-select-sm {
  &:focus {
    color: black;
  }
}

.teamMembers {
  &-accordion {
    &_collapse {
      .collapse:not(.show) {
        display: block !important;
      }
    }
  }
}

// General document
.generalDoc {
  &-header {
    display: flex;
  }

  &-uploadBtn {
    margin-left: 2rem;
  }

  &-cancelBtn {
    color: black;
    background: white;
    border: 1px solid black;
  }

  &-addBtn {
    position: absolute;
    top: 50px;
    right: 118px;
    color: black;
    background: white;
    border: 1px solid black;
  }
}

// custom-doughnut
.custom-doughnut {
  &_centreText {
    color: #000;
    font-family: $rubik;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
//@include media-breakpoint-up(sm) {
//}

// @media (min-width: 768px) {
//   .sitevisit {
//     &-inputBtn {
//       margin: 1rem 0rem;
//     }
//   }
// }

// media query for less than 576px
@media (max-width: 768px) {
  .pieChart {
    &-content {
      // margin-top: 3rem;
      padding-top: 1rem;
    }

    &-hr {
      width: 111%;
      left: -15px;
    }
  }

  .login {
    &-header {
      &_right {
        width: 50% !important;
      }
    }
  }

  .react-calendar_content-container {
    border: none !important;
  }

  // .sitevisit {
  //   &-inputBtn {
  //     margin: 1rem 0rem;
  //   }
  // }
}

@media (max-width: 992px) {
  .sitevisit {
    &-inputBtn {
      margin: 1rem 0rem;
    }
  }
}

@media (max-width: 576px) {
  .pieChart {
    &-content {
      margin-top: 1rem;
      height: 18rem;
    }

    &-hr {
      width: 107%;
    }
  }

  .sitevisit {
    &-inputBtn {
      width: 100%;
    }
  }
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
.MuiSelect-select {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-right: 30px !important;
  padding-bottom: 10px !important;
  padding-top: 5px !important;
}

// MOM text area
.css-1rb63tl-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid grey !important;
}

label + .css-1rb63tl-MuiInputBase-root-MuiInput-root {
  margin-top: 0px !important;
}

.no_items {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1400px) {
  .container-lg {
    max-width: 1400px !important;
  }
}

@media (min-width: 1400px) {
  .container-lg {
    max-width: 1400px !important;
  }
}
// Dynamically upload photos with comments in photos, material spec and site visit page
.dynamicPhotosComments {
  &-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    margin-bottom: 1rem;
  }

  &-img {
    height: 150px;
    border-radius: 1rem;
    border: 2px solid grey;
    object-fit: cover;
  }

  &-clearCol {
    align-self: flex-start;
  }

  &-clearBtn {
    align-self: flex-start;
    border-radius: 50%;
    background: grey;
    border: 2px solid grey;
  }
}

._3dviews {
  display: inline;
  width: auto;
  position: relative;
  overflow: hidden;
  &-preview-img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0px 0px 1px 0px #000;
  }
  &-preview-clearBtn {
    position: absolute;
    top: 0px;
    right: 11px;
    border-radius: 0px 10px 0px 10px;
    font-size: 8px;
    width: 25px;
    height: 25px;
    padding: 0;
  }
  &_tdImageScrollDiv {
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 6px;
    }
  }
}

.no-border-bottom > td {
  border-bottom: none;
}

._3dviews_images {
  & > td {
    & > img {
      width: 100px;
      height: 100px;
      box-shadow: 0px 0px 1px 0px #000;
      margin: 0 10px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.btn.btn-link {
  text-decoration: none;
  color: #000 !important;

  &:focus-visible {
    box-shadow: none;
  }
}

.document-images-view {
  display: flex;
  overflow: auto;
  padding: 10px 0px !important;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(204, 204, 204, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background: rgba(204, 204, 204, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.MuiInputBase-formControl {
  font-family: $rubik !important;
  color: #8d8d8d !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.MuiInputLabel-formControl {
  font-family: $rubik !important;
  color: #303030 !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.MuiInputBase-input.MuiInput-input {
  font-family: $rubik !important;
  color: #303030 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;

  &::after {
    color: #000000 !important;
  }

}

.Mui-disabled{
  -webkit-text-fill-color:rgba(30, 30, 30,1) !important
}

.rubik {
  font-family: $rubik;
}

.fw-500 {
  font-weight: 500;
}

.error-bottom {
  .Mui-error::before {
    border-bottom-color: gray !important;
  }
}

/*new custom scss*/
.MuiInput-underline:after {
  border-bottom: 2px solid #000 !important;
}
.menuItem {
  font-family: $rubik !important;
}

.paymentinfo-bargraph-timeline-fontsize span {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #666666;
}

.Typical-Floor {
  #mutiple-select-label {
    top: -7px !important;
    left: -14px !important;
  }
}

@media (max-width: 767.98px) {
  .sidebar:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 16rem !important;
  }

  .fixed-sidebar:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 4rem !important;
  }

  html:not([dir="rtl"]) .sidebar:not(.sidebar-end):not(.show) {
    margin-left: 0;
  }
}

.views-table {
  .table > :not(caption) > * > * {
    padding: 1rem 0.5rem !important;
  }
}

.modal-dialog {
    max-width: 50vw;
} 

.MuiMenuItem-root{
  font-family: $rubik !important;
  font-size: 14px !important;
}

.custom-checkbox-label {
  font-family: $rubik !important;
  font-size: 14px !important;
}

.MuiFormControlLabel-label{
  font-family: $rubik !important;
  font-size: 14px !important;
}

.MuiCheckbox-root {
  color:#303030;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #303030;
}

.focusArea{
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 15px;
}

.focustxt {
  font-size: 14px;
  color:#303030;
}

.DeadlineTodayIcon{
  height: 2.15rem;
  width: 2.15rem;
  margin-right: 15px;
}

.DeadlineTomoIcon{
  height: 2.3rem;
  width: 2.3rem;
  margin-right: 10px;
}

.accordion-MOM-textField{
  width: 99% !important;
  color: #454444;
  font-size: 15px;
}

.nodata-container {
  height: 70vh;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}