.TaskBoard {
  .TaskColumn {
    // margin: 8px;
    padding: 0.5rem;
    display: flex;
    width: 100%;
    min-height: 70vh;
    // overflow: scroll;
    position: relative;
    overflow-x: hidden;

    .TeamMemberList {
      margin: 10px;

      & > div {
        // margin: 15px auto;
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 10px;
        cursor: pointer;
        & > img {
          object-fit: cover;
          border-radius: 50px;
          margin-right: 10px;
          border: 1px solid #b8b8b8;
        }
        & > h6 {
          margin-bottom: 0;
        }
      }
    }

    .TaskList {
      height: fit-content;
      display: flex;
      flex-direction: column;
      background: linear-gradient(0deg, #f6f6f6, #f8f8f8);
      min-width: 0;
      flex-grow: 1;
      flex-basis: 0;
      width: auto;
      border-radius: 5px;
      padding: 15px 10px;
      margin-right: 10px;
      box-shadow: inset 0px 0px 5px 0px #e5e5e5;
      border-radius: 10px;

      .ColumnTitle {
        border-bottom: 1px solid #d9d9d9;
        // padding-bottom: 10px;
        padding: 12px 10px;
        margin: 0;
        position: sticky;
        top: -1rem;
        background: linear-gradient(0deg, #f6f6f6, #f8f8f8);
        font-weight: 700;
        font-size: 14px;
        }

      .TaskInformation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
        min-height: 100px;
        max-width: 100%;
        /* background: ${({ isDragging }) =>
                    isDragging ? 'rgba(255, 59, 59, 0.15)' : 'white'}; */
        border-radius: 10px;
        background: white;
        margin-top: 15px;
        box-shadow: -1px 2px 10px 0px #66666670;

        &-content {
          display: flex;
          width: 100%;
          align-items: center;

          &_title {
            width: 90%;
            margin-bottom: 0rem;
            font-size: 14px;
            font-weight: 600;
            color:#000;
          }

          &_icon {
            height: 1rem;
            width: 1rem;
          }
        }

        .secondary-details {
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          margin-top: 5px;
          p {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  &-teamMember {
    &_active {
      border: 1px solid;
      padding: 10px;
      border-radius: 10px;
      background: white;
    }
  }

  &-date {
    .MuiInputBase-input.MuiInput-input {
      width: 100%;
    }
  }

  &-modal {
    &_title {
      font-family: $rubik !important;
      display: flex;
      justify-content: space-between;
      font-size: 18px !important;
      font-weight: 600 !important;

      &Icons {
        display: flex;
        width: 20%;
        justify-content: space-evenly;
      }
    }
  }

  &-switch {
    display: flex;
    justify-content: space-between;
    height: 2rem;
    margin: 1rem 1rem;
    align-items: center;
    float: right;
    width: max-content;
    margin-right: 0rem;

    &_header {
      color: #161616;
      font-family: $rubik;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
    }

    .MuiSwitch-root {
      margin-right: 0.5rem;
    }
  }

  &-AssigneeColumn {
    width: 20%;
    height: 70vh !important;
    overflow: scroll;
    overflow-x: hidden;
  }

  &-StatusContainer {
    //display: flex;
    width: 100%;
    //max-height: 70vh;
    // overflow-y: scroll;
    // overflow-x: hidden;

    .infinite-scroll-component__outerdiv {
      width: 100% !important;
    }
  }

  .MuiDialogTitle{
    font-family: $rubik;
  }
}
.form-estimated{
  //margin-top: 1.6rem;
  color: rgb(86, 83, 83);
}






/* Task Details modal title */
.TaskBoard-modal_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* FontAwesome icons for Administrator actions */
.taskboard-icon-calendar{
  cursor: pointer;
  color: gray;
  margin-left: 490px;
  align-items: flex-end;
}


/* Calendar and Input Field Section */
.taskboard-calendar-container .react-calendar {
 // display: flex;
  //flex-direction: column;
  //align-items: center;
  margin-top: 25px;
  width: 100% !important;
  max-width: none;
}

.task-selected-date-label {
  font-weight: 10px;
  margin-bottom: 5px;
}
.taskboard-input-row {
  display: flex;
  align-items: center;
  gap: 10px; /* Add space between input and button */
  width: 100%; /* Make sure row does not exceed full width */

}

.taskboard-input-field {
 width: 50%;
  //margin-top: 5px;
 // flex: 1;
  padding: 8px;
  border-radius: 20px;
  border-width: thin;
  margin-left: 35px;
}

.taskboard-submit-button {
//  margin-top: 10px;
padding: 8px;
cursor: pointer;
border-radius: 20px;
width: 15%;
border-width: thin;
background-color: black;
color: white;
margin-left: 25px;
}

/* Display submitted data */
.taskboard-submitted-data {
  margin-top: 10px;
}

.calendar-effort-info{
color: crimson;
font-size: 11px;
font-weight: 600;
}

.react-calendar__tile:disabled {
  color: #ababab;
  background-color: transparent !important;
}