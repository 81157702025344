.changeRequest {
  &-tooltip {
    height: max-content;
    width: 9rem;
    display: flex;
    flex-direction: column;
    //bottom: -125%;
    align-items: center;
  }

  &-editModal {
    .MuiDialog-paperScrollPaper {
      max-width: 60rem !important;
      width: 38rem;
      height: 29rem;
    }
  }
}
