.chat {
  &-form {
    display: flex;
    align-items: flex-end;
  }
}

.wy-appbar .wy-button-icon {
  display: none;
}

.wy-messenger-provider {
  max-height: 60vh;
}

.wy-footerbar::after {
  width: 100% !important;
}
