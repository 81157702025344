.table-datacell_file {
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }

  &-active {
    color: green;
  }
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
  font-family: $rubik !important;
}

// Material UI - dropdown select property
.css-l4u8b9-MuiInputBase-root-MuiInput-root {
  font-family: $rubik !important;
}

//notification popover component
.notification {
  &Icon {
    cursor: pointer;
    margin-right: 0.7rem;

    &_count {
      position: absolute;
      bottom: 16px;
      left: 17px;
      font-family: $rubik;
      font-size: 10px;
      background: red;
      border-radius: 50%;
      padding: 3px 4px 2px;
      color: white;
    }
  }

  &_popover {
    &-container {
      .MuiPopover-paper {
        width: 30vw;
        border-radius: 1rem;
        top: 50px !important;
        transition: opacity 364ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          transform 342ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
        transform-origin: 971.069px 0px !important;
      }
    }

    &-cardbody {
      padding: 2rem 1rem !important;
    }

    &-headerDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $rubik;
    }

    &-tabs {
      border-bottom: 1px solid black;

      .MuiTab-textColorPrimary {
        color: black !important;
        font-size: 12px;

        &.Mui-selected {
          border-bottom: 2px solid black;
        }
      }
    }

    &-h1 {
      font-size: 1.2rem;
    }

    &-h2 {
      text-decoration: underline;
      font-size: 0.9rem;
      cursor: pointer;
    }
  }

  &_card {
    &-container {
      &::-webkit-scrollbar {
        display: none;
      }
    }

    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid gray;
    cursor: pointer;

    &-icon {
      margin: 0rem 1rem;
      background-color: #e1e1e1;
      border-radius: 50%;
      padding: 3px;
      font-size: 30px;
      width: 2rem !important;
      height: 2rem !important;
    }

    &-text {
      display: flex;
      flex-direction: column;
      margin: 10px 0px;
    }

    &-h1 {
      font-size: 14px;
    }

    &-h2 {
      font-size: 12px;
    }
  }

  &-infinteScroll {
    height: auto;
    max-height: 60vh;
    overflow: auto;
  }
}
