.plans-modal {
  .modal-dialog {
    display: flex;
    justify-content: center;
    height: 90%;
    max-width: 70% !important;

    .modal-content {
      height: 100%;
    }
  }

  & .webgage {
    padding: 30px 0 !important;

    & section {
      padding: 0px !important;
    }

    & .pricing {
      padding: 0px !important;
      & .box {
        padding: 15px 20px;
      }

      & .price {
        font-size: 30px !important;
      }

      &h3 {
        margin-bottom: 8px !important;
      }
    }
  }

  &_card {
    padding: 1rem;
  }

  &-body {
    padding: 0px !important;
  }
}

.plans-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  backdrop-filter: blur(5px);
}

.plans-modal {
  background: rgb(255 255 255 / 98%);
  border-radius: 8px;
  max-width: 72%;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: relative;
  height: 97%;
}

.plans-modal-header {
  // display: flex;
  // justify-content: flex-end;
  position: absolute;
  right: 0px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.webpage .pricing .box {
  padding: 20px 20px !important;
}

.plans-modal-body {
  height: 100% !important;
  & .price {
    font-size: 30px !important;
  }
  & .box {
    & ul {
      line-height: 24px !important;
      font-size: 14px !important;
      margin-bottom: 0px !important;
    }
    & li {
      padding-bottom: 5px !important;
    }
  }
  & .aos-animate {
    // transform: translateZ(0) scale(0.97) !important;
  }
}

.order-summary {
  // background-color: #f9f9f9;
  // padding: 20px;
  // border-radius: 10px;
  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  h3 {
    text-align: left;
  }

  p {
    margin: 10px 0;
    font-size: 18px;
  }

  &-itemsDiv {
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  &-items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
  }
}

.payment {
  &-radio-button {
    .form-check-input {
      cursor: pointer;
    }
    .form-check-input:focus {
      box-shadow: 0px 0px 10px 0px !important;
    }
    .form-check-input:checked {
      border-color: black !important;
      background-color: black !important;
    }
  }
}

.pricing {
  &-backButton {
    position: absolute;
    top: 50%;
    left: 4px;
    font-size: 2rem !important;
    margin: 0;
    cursor: pointer;
  }
}

.webgage {
  height: 100% !important;
}

.pricing {
  height: 100% !important;
}

.container {
  // height: 102%;

  .aos-animate {
    // height: 98% !important;
  }

  .btn-buy {
    // margin-top: 1rem !important;
  }
}

.webpage .pricing .box {
  padding: 20px 20px 20px 20px !important;
  height: 100%;
}

.plan-input {
  height: 2.5rem;
  font-size: 14px;

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
  }
}

.paymentForm {
  &-Starter {
    height: 107% !important;
  }

  &-allUICard {
    transform: translateZ(0) scale(0.92);
  }

  &-box {
    height: 102% !important;
    // box-shadow: 0px 3px 5px !important;
  }

  &-boxHeight {
    height: 90% !important;
    // box-shadow: 0px 3px 5px !important;
  }

  &-textFieldContainer {
    height: 33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-textFieldGroup {
    position: relative;
    color: #666666;
    margin-bottom: 0.5rem;

    &_Icon {
      position: absolute;
      right: 7%;
      top: 25%;
      color: lightgray;
    }
  }

  &-textField {
    height: 2rem;
    font-size: smaller;
  }
}

.webpage .pricing .upgrade-business-box {
  padding: 20px !important;
}

.additionalStorage {
  &-paymentForm {
    height: 96% !important;
    box-shadow: 0px 3px 5px !important;
  }
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}
