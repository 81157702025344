.confirm-box {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 999;
    transition: opacity 0.3s ease-in-out;

    &.visible {
        display: block;
    }

    .box {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }

    p {
        margin: 0 0 15px;
    }

    .button-container {
        text-align: right;

        button {
            margin-left: 10px;
            padding: 3px 15px;
            cursor: pointer;
        }
    }
}