// If you want to override variables do it here
@import "variables";

// react-calendar css
@import "react-calendar/dist/Calendar.css";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

@import "@weavy/uikit-react/dist/css/weavy.css";

// AppAccordion component - Text field
.MuiInputBase-input.MuiInput-input {
  padding-bottom: 15px;
}
// React-photo-view CSS
@import "react-photo-view/dist/react-photo-view.css";
// If you want to add custom CSS you can put it here.
@import "custom";
@import "utils";
@import "skeleton";
@import "taskboard.scss";
@import "drawings.scss";
@import "changeRequest.scss";
@import "walkthrough.scss";
@import "termsConditions.scss";
@import "chat.scss";
//@import "help&Support.scss";

@import "helpSupport.scss";

@import "swiper.scss";
@import "paidPlans.scss";
// stylenew
// .material-specific .react-photo-album--row{
//   height:300px;
// }
.upload-revision-image {
  padding: 0px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  position: relative;
}
.upload-revision-close-btn {
  width: 24px;
  padding: 0px;
  height: 24px;
  display: flex;
  right: 0px;
  top: 0px;
  font-size: 12px;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 0px 10px 0px 10px;
}
.material-specific .react-photo-album--column > div + div {
  margin: 10px 0px !important;
}

// .material-specific .react-photo-album--masonry {
//   column-gap: 2px !important;
//   justify-content: unset !important;
// }
// .material-specific .react-photo-album--row img{
//   height:100%!important;
// }
.card-image-view .react-photo-album--row div {
  max-width: 350px !important;
}
.accordion-card {
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0px;
  border: 1px solid #cfcfcf;
}
.add-btn-doc {
  border-radius: 0.625rem;
  background: #000;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 5px 20px 5px 20px;
  color: #fff;
  cursor: pointer;
  text-transform: capitalize;
  box-shadow: 1px 1px 10px 1px #66666699;
}
.simplebar-content {
  overflow-x: hidden;
}
.milestone-body,
.milestone-table-view {
  // height: 300px;
  // overflow-y: auto;
}
.header-accordion button {
  padding: 0px !important;
}
.header-accordion .accordion-add-close-btn {
  width: 100%;
  padding: 10px;
  text-align: center;
}
.Typical-Floor fieldset {
  border: none !important;
  border-bottom: 1px dotted !important;
  border-radius: 0px !important;
}
.accordion-add-close-btn {
  position: absolute;
  right: 0;
  background: black;
  color: white;
  padding: 4px 10px;
  z-index: 999;
  font-weight: 300;
  border-radius: 10px;
}
.nav-item {
  position: relative;
  display: flex;
  align-items: center;
}
.label-design + svg {
  position: absolute;
  left: 35%;
}
.label-design-1 + svg {
  position: absolute;
  left: 9%;
}
.label-design-title {
  position: absolute;
  left: 26%;
}
.label-design-1 + svg + span {
  position: absolute;
  left: 26%;
}
.label-design-1 {
  width: 40px;
  top: 17% !important;
  left: 46% !important;
}
.label-design,
.label-design-1 {
  position: absolute;
  top: -16px;
  font-size: 12px;
  right: 2%;
  background: white;
  padding: 0px 5px;
  border-radius: 4px;
  color: black;
}
.summary-tooltip {
  width: auto !important;
  height: auto !important;
  display: flex;
  flex-direction: column !important;
  justify-content: start !important;
  align-items: start !important;
}
.changeRequest-tooltip {
  width: 12rem !important;
}
.dynamicPhotosComments-clearBtn {
  font-size: 10px;
  width: 20px !important;
  height: 20px;
  border-radius: 50%;
  display: flex;
  padding: 0px !important;
  justify-content: center;
  align-items: center;
}
.MuiTooltip-tooltip {
  background: none !important;
}
.MuiTooltip-popper[data-popper-placement*="bottom"]
  .css-ja5taz-MuiTooltip-tooltip {
  margin-top: 0px !important;
}
.custom-header_user {
  margin: 0px !important;
}
.m-0 {
  margin: 0px !important;
}
.logout-btn {
  background: #ebedef;
  position: absolute;
  bottom: -14%;
  right: 1.5%;
  padding: 5px 10px;
  border: 1px solid #e1e1e1;
  background-color: #fff !important;
  border-radius: 10px;
  cursor: pointer;
}
.canvas-height {
  height: 250px !important;
  width: 300px !important;
}
.canvas-height .canvas {
  height: 100% !important;
  width: 100% !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.table-overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.table-overflow::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.table-overflow::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 1px solid #555555;
}

.pi-chart {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.overflow-view::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.overflow-view::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.overflow-view::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 1px solid #555555;
}
.changerequest-btn {
  box-shadow: none !important;
}
@media screen and (max-width: 1450px) {
  .canvas-height {
    height: 200px !important;
    width: 250px !important;
  }
}

.Toastify__toast-body > div:last-child {
  font-family: $rubik !important;
}

.paymentFailure {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.margin-left-1 {
  margin-left: 1rem !important;
}

.margin-right-1 {
  margin-right: 1rem !important;
}


//UPGRADE PAYMENT FORM STYLES
.upgrade-col{
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  text-align: left;
  max-width: 600px;
  width: 100%;
  height: 70%; 
}
.upgrade-tab-navigation{
  margin-top: 1px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upgrade-tab-button{
  background-color: transparent;
  color: black;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-bottom: 2px;

  &.active {
    font-weight: bold;
  }
}
.upgrade-tab-divider{
  width: 2px;
  height: 25px;
  background-color: lightgray;
  margin: 0 5px;
  border-width: 1px;
}

.upgrade-form-input{
margin-top: 0.5rem;
margin-bottom: 0.5rem;
border: 1px solid #666;
outline: none;
border-radius: 1.3rem;
width: 18rem;
height: 2.3rem;
text-align: left;
position: relative;
color: #666666;
box-sizing: border-box;
padding: 8px 40px;
font-size: 16px;

}
.upgrade-form-input:focus {
 border: 1px solid #666;
  border-color: 1px solid black; 
}

.upgrade-form-input-gst-coupon{
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
    border: 1px solid #666;
    outline: none;
    border-radius: 1.3rem;
  width: 20rem;
  height: 2.5rem;
text-align: left;
position: relative;
color: #666666;
box-sizing: border-box;
}

.upgrade-error-msg{
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
.upgrade-valid-msg{
  color: green;
  margin-top: 5px;
}
.payment-radio-button.custom-radio-button {
  margin-left: 30px;
  min-width: 100%;
  box-sizing: border-box;
  padding-left: 35px;

  .plan-duration-span {
    margin-bottom: 0.7rem;
  }
}

.upgrade-order-summary{
  width: 80%;
  padding-left: 10px;
  padding-right: 10px;
     .upgrade-order-summary-title{
      margin-bottom: 0.7rem;
     }
     .order-summary-itemsDiv {
      font-size: 15px;
    }
  
    .order-summary-items.plan-duration {
      justify-content: space-between;
      margin-right: 10px;
      display: flex;
  
      .plan-duration-value {
        margin-left: 150px;
      }
    }
  }

  .form-inputGroup_Button {
    background-color: #000000;
    color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 2.5rem;
    margin: 1rem 0rem !important;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    margin-top: 0px;
    position:relative;
  }
  
  .form-inputGroup_Button:hover {
    background-color: #000000;
  }

/*styles for the coupon code dropdown */


.coupon-dropdown .dropdown-item span {
  font-size: 12px; /* Adjusted font size for coupon code and discount text */
}

.coupon-dropdown .dropdown-item span:last-child {
  font-size: 12px; /* Reduced font size for the discount limit text */
}

/* Apply button styles */
.apply-btn {
  background-color: #000;
  color: white;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 10px;
  line-height: 12px;
  border: none;
}






