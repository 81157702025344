html {
  scroll-behavior: smooth !important;
}

.help {
  &-textarea {
  //  font-family: $montserrat;
    font-weight: 500;
    color: gray;
  }
  &-modal {
    &_container {
      .MuiDialog-paperWidthSm {
        max-width: min-content !important;
      }
      
    }
  }
  &-previewImage {
    &_closeIcon {
      position: absolute;
      top: 0px;
      right: -7px;
      background: gray;
      border: 0;
      font-size: x-small;
    }
  }
}


.support-container {
  display: flex;
  flex-direction: row;
  //align-items: center;
 // padding: 50px;
  margin: 20px auto;
  text-align: left;
}

.support-card {
  width: 100%;
 // text-align: center;
  padding: 20px;
  background-color: white; 
}

.support-header h5 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  padding-left: 10px;  
}
.help-search-div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 300px;
  margin-bottom: 20px;
}
.help-search {
  width: 100%;
  padding: 10px;
  padding-left: 10px; 
  border-radius: 5px;
  border: 1px solid #ced4da;
}
.help-searchIcon {
  position: absolute;
  left: 15px;
  font-size: 25px;
  color: #6c757d;
}
.try-luck-btn {
  display: flex;
  justify-content: center; 
  align-items: center;
  margin-bottom: 20px;
  width: 275px;
  border-radius: 20px;
}
.try-luck-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: black;
  color: white;
  border: none;
}
.support-image {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  align-items: center;
}


.support-card-container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px; 
  padding: 20px;
  box-sizing: border-box;
  background-color: #f9fbff; 
  border-radius: 12px;
}
.support-card1 {
  background-color: #ffffff; 
  border-radius: 12px; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  text-align: center; 
  padding: 20px;
  transition: transform 0.3s ease; 
}
.support-card1:hover {
  transform: translateY(-5px); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); 
}
.support-card-body1 {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  padding: 15px;
}
.support-header1 h6 {
  font-size: 16px;
  margin-bottom: 13px;
  font-weight: 500; 
  color: #222d39; 
  position: relative;
  padding-bottom: 12px; 
}
.support-header1 h6::after {
  content: '';
  display: block;
  width: 60px;
  height: 2px; 
  background-color: #666; 
  margin: 5px auto 0; 
  border-radius: 2px;
}
.support-header1 p {
  font-size: 12px;
  color: #666; 
  margin-top: 10px;
}
.support-icon {
  font-size: 40px; 
  color: #4a90e2; 
  margin-bottom: 15px;
}
.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  background-color: #ffffff;
  border-radius: 12px;
  margin-bottom: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
}
.search-container h1 {
  font-size: 26px;
  color: #222d39;
}
.search-bar {
  flex-grow: 1;
  margin-right: 20px;
}
.search-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
}
.search-button {
  padding: 10px 20px;
  background-color: #4a90e2;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
.search-button:hover {
  background-color: #357ab8;
}

.help-puzzlepieceIcon{
  color: purple;
 font-size: 35px;
 margin-bottom: 10px;
  
}
.help-diceIcon{
  color: #6ea7e8;
  font-size: 35px;
 margin-bottom: 10px; 
}
.help-moneybillIcon{
  color: #1fce82;
  font-size: 35px;
 margin-bottom: 10px; 
}
.help-computerIcon{
  color: #e05bbf;
  font-size: 35px;
 margin-bottom: 10px; 
}
.help-safeIcon{
  color: #a0541a;
  font-size: 35px;
 margin-bottom: 10px; 
}
.help-questionIcon{
  color: #d83a3a;
  font-size: 35px;
 margin-bottom: 10px; 
}
.help-userIcon{
  color: #379eb5;
  font-size: 35px;
 margin-bottom: 10px;
}
.help-serverIcon{
  color: #ea9723;
  font-size: 35px;
 margin-bottom: 10px;
}
.justified-text {
  text-align: justify;
}
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh; 
  margin: 40px auto; 
  max-width: 75vw;
}
.modal-content {
  margin: auto; 
  max-height: 90vh; 
  overflow-y: auto; 
  padding: 30px;
}
.footer-modal{
  margin-bottom: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  padding-bottom: 30px 0;
 
}
.support-card-container1 .support-card1 .custom-bold {
  font-weight: bold;
  color: #0c0c0c; 
}








