@import "react-multi-carousel/lib/styles.css";
.walkthrough {
  &-overflow {
    &_container {
      display: flex;
      flex: 1;
      overflow: auto;
    }
    &_div {
      display: flex;
      min-height: min-content;
    }
  }

  &-switch {
    margin: 0rem 1rem !important;
    font-family: $rubik;
    &Container {
      align-self: end;
      display: flex;
      justify-content: center;
    }
  }

  &-modal {
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
      height: 30rem;
      width: 43rem;
      max-width: 43rem;
    }
  }

  &-carousel {
    cursor: pointer;
    padding: 1.5rem;
  }

  &-title {
    margin: auto;
    padding: 0rem;
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }

  &-image {
    object-fit: cover;
    height: 100%;
  }

  &-allVideos {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 1rem;
    padding: 1rem;
  }
}

.video-thumbnail {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 30px;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: "\f01d";
    //   font-family: FontAwesome;
    font-size: 100px;
    color: #fff;
    opacity: 0.8;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
  &:hover:before {
    color: #eee;
  }
}

.image {
  width: 100%;

  &Container {
    height: 80%;
    position: relative;
  }
  &Button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    height: 5rem;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &Trash {
    position: absolute;
    top: 5px;
    right: 5px;
    background: gray;
    cursor: pointer;
    border-radius: 50%;
    padding: 8px;

    path {
      fill: white;
    }
  }
}
